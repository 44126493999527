<template>
  <div id="CommoditySearch">
    <Dialog ref="dialog" :config="config">
      <!-- <span>插槽内容</span> -->
    </Dialog>
    <HomePageTop ref="head"></HomePageTop>
    <keywordSearch @filterData="filterData" />
    <noData v-if="shopType == 1688 && goodsListIsNull1688" />
    <div v-else>
      <!-- 搜索结果块状 -->
      <div class="SearchConterCrosswise" v-loading="loading" v-if="Crosswise">
        <!-- $%2 -->
        <router-link
          :to="
            '/ProductDetails?goods_id=' +
              item.goods_id +
              '&LP=' +
              $route.query.keyword
          "
          class="SearchConterCrosswiseCon"
          v-for="(item, index) in showList"
          :key="index"
          target="_blank"
        >
          <!-- <button
          class="similarProducts"
          @click="
            $store.commit('getsearchImg', item.imgUrl);
            $fun.routerToPage('/CommoditySearch?type=imgSearch');
            $router.go(0);
          "
        >
          {{ $fanyi("查看相似") }}
        </button> -->
          <!-- 放图片的div -->
          <div class="SearchConterCrosswiseImg">
            <!-- 遮罩 -->
            <!-- <div class="shade" @click="$fun.toCommodityDetails(item.goods_id)">
            <div><img src="../../assets/newImg/搜索小.png" alt="" /></div>
          </div> -->
            <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
            <el-image :src="item.imgUrl" class="biggoodsImg">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <!-- <loadingImg :src="item.imgUrl" /> -->
          </div>
          <!-- 显示其他信息的div -->
          <div class="rests">
            <p class="goodsTitle" :title="item.title">{{ item.title }}</p>
            <p class="flex acenter">
              <span
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
                  )
                }}€</span
              >{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥
            </p>
            <div class="totalTagBox">
              Ventas mensuales: {{ item.monthSold }} artículos
            </div>
            <div class="totalTagBox">
              Tasa de recompra: {{ item.repurchaseRate }}%
            </div>
          </div>
        </router-link>
      </div>
      <!-- 搜索结果横向 -->
      <div class="SearchConterVertical" v-loading="loading" v-else>
        <div
          @click="
            $router.push('/ProductDetails?goods_id=' + item.goods_id, true)
          "
          v-for="(item, index) in showList"
          :key="index"
        >
          <el-popover placement="right" trigger="hover">
            <img
              :src="item.imgUrl"
              alt=""
              style="width: 300px; height: 300px"
            />
            <el-image
              class="goodsImg hengxiang"
              :src="item.imgUrl"
              style="width: 160px; height: 160px"
              slot="reference"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
          </el-popover>

          <!-- <img class="goodsImg" src="../../assets/newImg/加载中.gif" /> -->
          <div class="VerticalInfo">
            <span class="vGoodsTitle">{{ item.title }}</span>
            <span
              >{{
                $fun.EURNumSegmentation(
                  $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
                )
              }}€（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）<font
                >{{ $fanyi("销量") }}：{{ item.sales }} {{ $fanyi("件") }}
              </font></span
            >
          </div>
          <span class="collect" @click.stop="favoriteGoodsAdd(item)">
            <div>
              <img
                v-if="item.goodsFavoriteStatus"
                src="../../assets/SearchResultsPage/Collected.png"
              />
              <img v-else src="../../assets/SearchResultsPage/collection.png" />
            </div>
            <span> {{ $fanyi("收藏") }}</span>
          </span>
        </div>
      </div>
      <!-- 加载中提示 -->
      <div class="loadingProBox" v-if="shopType == 1688 && !finished1688">
        <i class="el-icon-loading"></i>
        <span>{{ $fanyi("加载中") }}</span>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>
<script>
import keywordSearch from "./components/model-keywordSearch.vue";
import noData from "./components/model-noData.vue";
import Foot from "../../components/foot/Foot.vue";
import Dialog from "../../components/public/Dialog";
import HomePageTop from "../../components/head/HomePageTop.vue";
export default {
  data() {
    return {
      form1688: {
        page: 0,
        pageSize: 50,
      }, //搜索条件
      list1688: [],
      goodsListIsNull1688: false,
      finished1688: false,
      total1688: 0,
      formTaobao: {
        page: 0,
        pageSize: 50,
      }, //淘宝搜索条件
      listTaobao: [],
      finishedTaobao: false,
      totalTaobao: 0,

      shopType: "1688",
      fiData: {},

      // 展示数据
      showList: [],
      total: 0,

      keyWord: "",

      loading: true,
      tiShi: this.$fanyi("加载中"), //提示语句
      // 是否是横版
      Crosswise: true,
      isImgSearch: false,
      notCreate: true,
      timeout: false,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定")],
      },

      loadKey: 1,
      nodataFont: this.$fanyi("没有找到商品，请换一个关键词试试"),
    };
  },
  components: {
    HomePageTop,
    Foot,
    Dialog,
    noData,
    keywordSearch,
  },
  computed: {},
  created() {
    this.form1688.descendOrder = false; //是否倒序;正序: false;倒序:true

    if (this.$route.query.type) {
      this.isImgSearch = true;
    }
    this.initialized();
  },
  watch: {
    $route: {
      handler(route) {
        if (route.name == "CommoditySearch") {
          if (this.$route.query.type == "imgSearch") {
            this.isImgSearch = true;
          } else {
            this.isImgSearch = false;
          }
          this.initialized();
        }
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollGetGoods);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollGetGoods);
  },
  methods: {
    // 筛选数据
    filterData(data) {
      this.shopType = "1688";

      this.form1688.page = 0;
      this.formTaobao.page = 0;

      this.list1688 = [];
      this.listTaobao = [];
      this.showList = [];
      this.fiData = data;

      this.finished1688 = false;
      this.finishedTaobao = false;

      this.goodsListIsNull1688 = false;

      this.timeout = false;
      this.loadKey++;
      this.getData();
    },
    toTop() {
      window.scrollTo(0, 0);
    },
    openChat() {
      this.$fun.clickJs();
    },
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      let datas = {
        goods_id: item.goods_id,
        title: item.title,
        image_url: item.imgUrl,
        price: item.goodsPrice,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return;
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      } else {
        this.$api
          .favoriteGoodsDelete({
            goods_id: item.goods_id,
          })
          .then((res) => {
            //console.log("favoriteGoodsDelete", res);
            if (res.code != 0) return;
            this.$message.success(this.$fanyi("移出收藏夹成功"));
            item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
            this.$forceUpdate();
          });
      }
    },

    // 路由改变时的操作
    initialized() {
      // 如果路由中带有CN就代表是点击首页的分类跳转过来的，需要读取路由中的中文关键词以进行精确搜索，否则获取路由中的普通关键词
      if (!!this.$route.query.CN) {
        //console.log(this.$route.query.CN);
        this.keyWord = this.$fun.fromCode(this.$route.query.CN);
      } else if (this.$route.query.keyword) {
        this.keyWord = this.$route.query.keyword;
      }
      this.finished1688 = false;
      this.finishedTaobao = false;

      // 讲关键词传到vuex以传值到头部搜索框
      this.$store.commit("getsearchInfo", this.$route.query.keyword);

      this.$forceUpdate();

      // this.getData();
    },
    // 获取列表数据
    async getData() {
      if (!this.timeout) {
        let loadKey = this.loadKey;
        this.timeout = true;
        let funshopType = this.shopType;
        let res = {};
        let resList = [];

        if (funshopType == "1688") {
          if (this.finished1688) {
            return (this.timeout = false);
          }
          if (!this.form1688.page) {
            this.loading = true;
            this.form1688.page = 0;
          }
          this.form1688.page++;
        }
        if (funshopType == "Taobao") {
          if (this.finishedTaobao) {
            return (this.timeout = false);
          }
          if (!this.formTaobao.page) {
            this.loading = true;
            this.formTaobao.page = 0;
          }
          this.formTaobao.page++;
        }

        if (this.$route.query.keyword) {
          let searchData = {
            keyWords: this.keyWord,
            shop_type: funshopType,
            page:
              funshopType == "1688" ? this.form1688.page : this.formTaobao.page,
            pageSize:
              funshopType == "1688"
                ? this.form1688.pageSize
                : this.formTaobao.pageSize,
            group_id: this.$route.query.group_id,
          };
          // 筛选只有1688才能用
          if (funshopType == "1688") {
            Object.keys(this.fiData).forEach((key) => {
              searchData[key] = this.fiData[key];
            });

            // let snidStr = [];
            // Object.keys(snId.value).forEach((key) => {
            //   snidStr.push(key + ":" + snId.value[key]);
            // });
            // snidStr = snidStr.join(";");
            // //console.log(snidStr);
            // searchData.snId = snidStr;
          }

          searchData.outMemberId = this.$fun.setUserID();
          res = await this.$api.EuropeKeywordSearchProduct(searchData);
          if (res.code == "10010") this.nodataFont = res.msg;

          resList = res.data.result;
        } else if (
          this.$route.query.type == "imgSearch" &&
          this.$route.query.imgUrl
        ) {
          let searchData = {
            shop_type: 1688,
            picUrl: this.$route.query.imgUrl,
            imageId: this.$route.query.imageId,
            page: this.form1688.page,
            pageSize:
              funshopType == "1688"
                ? this.form1688.pageSize
                : this.formTaobao.pageSize,
            outMemberId: this.$fun.setUserID(),
          };
          if (funshopType == "1688") {
            Object.keys(this.fiData).forEach((key) => {
              searchData[key] = this.fiData[key];
            });

            // let snidStr = [];
            // Object.keys(snId.value).forEach((key) => {
            //   snidStr.push(key + ":" + snId.value[key]);
            // });
            // snidStr = snidStr.join(";");
            // //console.log(snidStr);
            // searchData.snId = snidStr;
          }
          searchData.outMemberId = this.$fun.setUserID();
          res = await this.$api.imageSearchProductV2(searchData);

          resList = res.data.data;
        }
        // loadKey用于防止用户快速点击筛选项引起多个请求并发导致显示结果混乱问题
        if (loadKey != this.loadKey) return false;
        this.timeout = false;
        this.loading = false;

        if (res.code != 0) {
          if (funshopType == "1688") {
            if (this.form1688.page == 1 && !this.list1688.length) {
              this.goodsListIsNull1688 = true;
            }
            this.finished1688 = true;
            return;
          }
        }

        if (
          this.form1688.page == 1 &&
          !this.list1688.length &&
          (!resList || !resList.length)
        ) {
          this.goodsListIsNull1688 = true;
        }

        if (resList.length) {
          if (funshopType == "1688") {
            this.list1688 = [...this.list1688, ...resList];
          } else {
            this.listTaobao = [...this.listTaobao, ...resList];
          }
        }

        if (funshopType == "1688") {
          if (!resList || resList.length < this.form1688.pageSize) {
            if (
              this.form1688.page == 1 &&
              !(this.list1688.length + resList.length)
            ) {
              this.goodsListIsNull1688 = true;
            }
            this.finished1688 = true;
          }
        }
        this.favoriteGoodsCheckIn(res.data.result || res.data.data);
        // 加载状态结束
        this.loading = false;
        if (funshopType == 1688) {
          this.showList = this.list1688;
          this.total1688 = res.data.total;
        } else {
          this.showList = this.listTaobao;
          this.totalTaobao = res.data.total;
        }
        this.$forceUpdate();
      }
    },

    // 查看店铺是否已收藏
    favoriteGoodsCheckIn(reli) {
      if (!reli) return;
      let ids = [];
      reli.forEach((goodsItem) => {
        goodsItem.goodsFavoriteStatus = false;
        ids.push(goodsItem.goods_id);
      });
      this.$api
        .favoriteGoodsCheckIn({
          goods_id: JSON.stringify(ids),
        })
        .then((res) => {
          if (res.code != 0) return false;
          res.data.forEach((resItem, resIndex) => {
            reli[resIndex].goodsFavoriteStatus = resItem.inFavorite;
          });
        });
      this.$forceUpdate();
    },

    // // 页面滑动事件
    scrollGetGoods() {
      let dd = document.documentElement || document.body;

      if (dd.offsetHeight <= dd.scrollTop + 2000) {
        this.getData();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
/deep/.el-image.biggoodsImg {
  $imgSize: 218px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}
.SearchConterCrosswise {
  width: $pageWidth;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  min-height: 500px;
  position: relative;
  /deep/.el-loading-mask {
    z-index: 10;
  }

  .SearchConterCrosswiseCon {
    margin-right: 20px;
    margin-bottom: 15px;
    position: relative;
    transition: 0.3s;
    background-color: white;
    width: 264px;
    height: 373px;
    border: solid 1px #e8e8e8;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);

    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      transform: translate(0, -5px);
      box-shadow: 0px 10px 20px #d1d1d1;

      .similarProducts {
        opacity: 1;
      }
    }

    &:nth-child(5n + 1) {
      margin-right: 0px;
    }

    .SearchConterCrosswiseImg {
      height: 214px;

      overflow: hidden;
      height: 214px;
      position: relative;

      > .goodsImg {
        background-color: transparent;
      }

      .el-image {
        transition: 0.3s;
        width: 260px;
        height: 260px;
      }
    }

    .rests {
      box-sizing: border-box;
      padding: 16px 16px 24px;

      p {
        font-size: 12px;

        font-weight: 400;
        color: #000000;

        &.goodsTitle {
          @extend .text-overflow-one;
          height: 23px;
          font-size: 14px;
          font-weight: 600;
          color: #475467;
        }

        &:nth-child(2) {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;
          color: #475467;
          > span {
            line-height: 24px;
            color: #1a73e8;
            font-weight: 700;
            margin-right: 8px;
          }
        }

        .collect {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          span {
            font-size: 14px;

            color: #888888;
            line-height: 20px;
          }
        }
      }
      .totalTagBox {
        @extend .text-overflow-one;
        border-radius: 6px;
        border: 1px solid var(--Blue-200, #b2ddff);
        background: var(--Blue-50, #eff8ff);
        font-size: 13px;
        color: #1a73e8;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 8px;
        padding-left: 4px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.SearchConterVertical {
  width: $pageWidth;
  min-height: 300px;
  margin: 0 auto;

  > div {
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    position: relative;

    .goodsImg {
      width: 160px;
      height: 160px;
      margin-right: 20px;
      border-radius: 4px;
    }

    .VerticalInfo {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      > span {
        &.vGoodsTitle {
          font-size: 16px;
          width: 90%;

          color: #000000;
          line-height: 24px;
        }

        &:last-child {
          font-size: 24px;
          display: flex;
          align-items: center;

          font-weight: bold;
          color: #ffa724;
          line-height: 36px;
          position: relative;

          > font {
            width: 150px;
            font-size: 14px;

            color: #888888;
            line-height: 20px;
            position: absolute;
            left: 250px;
          }
        }
      }
    }

    .collect {
      position: absolute;
      right: 65px;
      top: 78px;
      text-align: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 14px;

        color: #888888;
        line-height: 20px;
      }
    }
  }
}
.loadingProBox {
  font-size: 20px;
  color: #888888;
  text-align: center;
  padding-bottom: 20px;
}
</style>

<template>
  <div class="goodsListIsNull">
    <div class="message">
      {{ nodataFont }}
    </div>

    <div class="expertAgent pageMoudle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style="enable-background: new 0 0 24 24"
        xml:space="preserve"
      >
        <path
          class="st0"
          fill="#94aff6"
          d="M11.4,1C6.6,1.3,3,5.5,3,10.3V17c0,1.7,1.3,3,3,3h1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2H5v-1.7 C5,6.4,8,3.1,11.8,3c4-0.1,7.2,3.1,7.2,7v2h-2c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h2v1h-6c-0.6,0-1,0.5-1,1s0.4,1,1,1h5 c1.7,0,3-1.3,3-3V10C21,4.8,16.6,0.7,11.4,1z"
        ></path>
      </svg>
      <div class="expertAgentCon">
        <h1>¿No encuentras lo que buscas?</h1>
        <p>
          Nuestro motor de búsqueda traduce automáticamente al idioma chino para
          realizar la búsqueda. Es posible que la traducción de las palabras
          clave que has introducido no sea correcta. Si necesitas ayuda para
          encontrar el producto que buscas ponte en contacto con un agente
          experto.
        </p>
        <button @click="openChat">Chatear con un agente</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    nodataFont() {
      return this.$parent.nodataFont;
    },
  },
  created() {},
  methods: {
    openChat() {
      this.$fun.clickJs();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.goodsListIsNull {
  width: 970px;
  margin: 0 auto;

  .message {
    width: 695px;
    height: 59px;
    margin: 110px auto 100px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 36px;
  }

  .expertAgent {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    // margin: 50px 0;
    padding: 40px 150px 55px 150px;
    margin-bottom: 80px;

    svg {
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 auto;
    }

    .expertAgentCon {
      width: 658px;
      padding: 10px;
      margin: 0 auto;

      > * {
        text-align: center;
      }

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h1 {
        font-size: 21px;
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8em;
        margin-bottom: 20px;
      }

      button {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        padding: 27px 80px 27px 80px;
        border-radius: 4px;
        border: #1a73e8 solid 2px;
        color: #1a73e8;

        &:hover {
          color: #ffffff;
          background-color: #1a73e8;
          border-color: #1a73e8;
        }
      }
    }
  }
}
</style>
